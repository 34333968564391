<template>
    <LiefengContent :isBack="$route.query.isLineHome || $route.query.isback" @backClick="backClick">
        <template v-slot:title>
            社区动员组织管理
            <span @click.stop="changeEye">
                <Icon :type="hasSensitive ? 'md-eye-off' : 'md-eye'" />
            </span>
            <!-- <Button type="error" v-if="$route.query.isLineHome && !$route.query.isback " @click="$router.push('/linehomepage')">返回</Button>
       <Button type="error" v-if="$route.query.isLineHome && $route.query.isback" @click="$router.push(`/mobilizationsystem?isLineHome=${$route.query.isLineHome}`)">返回</Button> -->
        </template>
        <template v-slot:toolsbarRight>
            <Form :label-colon="true" :inline="true" class="search">
                <FormItem>
                    <!-- <Cascader
                        transfer
                        @on-change="changeCasder"
                        :load-data="loadData"
                        change-on-select
                        style="margin-right: 10px; width: 250px"
                        :data="CascaderList"
                        v-model="CascaderId"
                        placeholder="请选择区/街道/社区"
                    ></Cascader> -->
                    <LiefengCascaderCity :orgCode="4401" :resetNum="0" @changeCasader="changeCasder" :value="orgCodeValue" :isShow="!!orgCodeValue"></LiefengCascaderCity>
                </FormItem>
                <FormItem>
                    <Input :maxlength="20" style="margin-right: 10px; width: 150px" placeholder="按姓名查询" v-model.trim="selectForm.name" />
                </FormItem>
                <FormItem>
                    <Input :maxlength="20" style="margin-right: 10px; width: 150px" placeholder="按手机号查询" v-model.trim="selectForm.mobile" />
                </FormItem>
                <FormItem>
                    <Select transfer style="width:180px;margin-right: 5px" v-model="selectForm.labelCode" placeholder="选择身份类型">
                        <Option :value="item.extendValue" v-for="(item, index) in identityType" :key="index">{{ item.dictValue }}</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <Select transfer v-model="selectForm.activeStatus" placeholder="按激活状态查询" style="width: 150px">
                        <Option value="">全部</Option>
                        <Option value="1">已激活</Option>
                        <Option value="2">未激活</Option>
                    </Select>
                </FormItem>
                <Button style="margin-right:10px" @click="inputSearch" type="primary" icon="ios-search">查询</Button>
                <Button style="margin-right:10px" type="success" @click="reset" icon="ios-refresh">重置</Button>
                <!-- <Button type="primary" @click="openMessage">发短信</Button> -->
                <Button style="margin-right:10px" type="primary" @click="$router.push('/smsrecordindex?name=mobilizationindex')">短信</Button>
                <Button style="margin-right:10px" type="primary" @click="$router.push(`/mobilizationsynchronized?orgId=${orgId}&isLineHome=${$route.query.isLineHome}&communityCode=${communityCode}`)">
                    导入并同步
                    <span style="color:red">({{ num }})</span>
                </Button>
            </Form>

            <!-- <Button type="error" @click="importExcel" icon="ios-add-circle-outline"
        >导入</Button
      > -->
        </template>
        <template v-slot:contentArea>
            <LiefengTable
                :talbeColumns="talbeColumns"
                :tableData="tableData"
                height="200"
                :loading="loading"
                :fixTable="true"
                :pagesizeOpts="[20, 30, 50, 100]"
                :curPage="page"
                :total="total"
                :page-size="pageSize"
                @hadlePageSize="hadlePageSize"
            ></LiefengTable>

            <!-- 导入excel弹窗 -->
            <LiefengModal title="导入" width="40%" :value="excelStatus" @input="excelModalData" class="export-modal">
                <template v-slot:contentarea>
                    <Form :model="searchForm" :label-width="100">
                        <FormItem>
                            <span slot="label" class="validate">请选择社区:</span>
                            <Tooltip placement="right-start" style="width:100%">
                                <div slot="content" style="width: 200px;white-space: normal">
                                    由于社区太多，请输入关键字进行刷选
                                </div>
                                <Select v-model="nodeId" filterable @on-change="getTree" placeholder="点击列表/输入搜索" :remote-method="loadingCommunityFn" :loading="loadingCommunity">
                                    <!-- 防止报错，使用了index -->
                                    <Option v-for="(item, index) in nodeList" :value="item.value" :key="index">{{ item.label }}</Option>
                                </Select>
                            </Tooltip>
                        </FormItem>
                    </Form>

                    <LiefengUploadExcel
                        style="margin:20px 0"
                        :showDownModal="{ isShow: true, downLink: './excelfiles/mobilization.xlsx', downName: '社区动员组织管理导入模板' }"
                        :action="exportAction"
                        :uploadData="uploadData"
                        :isShow="showExcel"
                        @success="excelSuccess"
                    ></LiefengUploadExcel>
                </template>
            </LiefengModal>
            <!--发短信-->
            <LiefengModal :value="messageStatus" @input="messageInput" title="发短信" :fullscreen="false" width="800px" height="540px">
                <template v-slot:contentarea>
                    <Message @SmsUser="SmsUser" ref="message" :isShow="showNumber"></Message>
                </template>
                <template v-slot:toolsbar>
                    <Button type="info" @click="messageBack" style="margin-right: 10px">取消</Button>
                    <Button style="margin: 0 5px 0 13px" :disabled="sendDidsabled" type="primary" @click="messageSend">确定发送</Button>
                </template>
            </LiefengModal>

            <!--设置负责区域-->
            <LiefengModal :value="messageRegion" @input="regionInput" title="设置负责区域" :fullscreen="false" width="800px" height="540px">
                <template v-slot:contentarea>
                    <Region :userId="regionUserId" ref="region" v-if="showRegion"></Region>
                </template>
                <template v-slot:toolsbar>
                    <Button type="info" @click="regionInput(false)" style="margin-right: 10px">取消</Button>
                    <Button style="margin: 0 5px 0 13px" :disabled="sendDisabled" type="primary" @click="sendRegion">确定</Button>
                </template>
            </LiefengModal>

            <!--修改-->
            <LiefengModal :value="editStatus" @input="editInput" title="修改" :fullscreen="false" width="500px" height="240px" class="export-modal">
                <template v-slot:contentarea>
                    <Form label-position="left" :label-width="100">
                        <FormItem>
                            <span slot="label" class="validate">姓名:</span>
                            <Input placeholder="请输入您的姓名" :maxlength="20" v-model="detaiMessage.name" />
                        </FormItem>
                        <FormItem>
                            <span slot="label" class="validate">地址:</span>
                            <Input placeholder="请输入您的地址" :maxlength="20" v-model="detaiMessage.addr" />
                        </FormItem>
                        <FormItem>
                            <span slot="label" class="validate">所属类型:</span>
                            <Select v-model="editSelect" multiple style="width:260px">
                                <Option v-for="item in selectList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                            </Select>
                        </FormItem>
                    </Form>
                </template>
                <template v-slot:toolsbar>
                    <Button type="info" @click="editInput(false)" style="margin-right: 10px">取消</Button>
                    <Button style="margin: 0 5px 0 13px" type="primary" @click="editSave">保存</Button>
                </template>
            </LiefengModal>
            <!-- 短信开通提醒 -->
            <LiefengModal title="短信开通提醒" :value="openTips" @input="changeOpenTips" width="500px" height="200px">
                <template v-slot:contentarea>
                    <p style="fontSize:16px">{{ orgName }}还未开通短信服务，通过随约系统发送短信，会产生短信费最高0.07元/条，是否提交开通此项服务的申请？如有疑问请联系社区实施专管员。</p>
                </template>
                <template v-slot:toolsbar>
                    <Button type="primary" style="marginRight:10px" :loading="opLoading" @click="goOpen">我要申请开通</Button>
                    <Button @click="notOpen">暂不开通</Button>
                </template>
            </LiefengModal>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
import LiefengUploadExcel from "./children/LiefengUploadExcel"
import Message from "./children/message.vue"
import Region from "./children/region.vue"

import LiefengCascaderCity from "@/components/LiefengCascaderCity"

export default {
    data() {
        return {
            //脱敏
            hasSensitive: true,
            opLoading: false,
            orgName: "",
            openTips: false,
            orgId: "",
            num: 0,
            // 列表搜索
            selectForm: {
                labelCode: "",
                name: "",
                activeStatus: "",
                mobile: "",
            },
            tableData: [],
            talbeColumns: [
                {
                    title: "区",
                    key: "zoneName",
                    width: 180,
                    align: "center",
                },
                {
                    title: "街道",
                    key: "streetName",
                    width: 180,
                    align: "center",
                },
                {
                    title: "社区",
                    key: "communityName",
                    width: 180,
                    align: "center",
                },
                {
                    title: "姓名",
                    key: "name",
                    width: 100,
                    align: "center",
                },
                {
                    title: "性别",
                    key: "sex",
                    width: 100,
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.sex == 0 ? "未知" : params.row.sex == 1 ? "男" : params.row.sex == 2 ? "女" : "未知")
                    },
                },
                {
                    title: "手机号码",
                    key: "mobile",
                    width: 130,
                    align: "center",
                },
                {
                    title: "证件号码",
                    key: "idNum",
                    width: 130,
                    align: "center",
                },
                {
                    title: "出生日期",
                    key: "birthday",
                    align: "center",
                    width: 120,
                    render: (h, params) => {
                        return h("div", {}, params.row.birthday ? this.$core.formatDate(new Date(params.row.birthday), "yyyy-MM-dd") : "")
                    },
                },
                {
                    title: "现居地址",
                    key: "addr",
                    minWidth: 180,
                    align: "center",
                },

                {
                    title: "所属类型",
                    key: "userType",
                    width: 180,
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.labelName.toString())
                    },
                },
                {
                    title: "所属职位",
                    key: "userType",
                    width: 180,
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.positionName.toString())
                    },
                },
                // {
                //    title: "负责区域",
                //     key: "regionName",
                //     width: 180,
                //     align: "center",
                // },
                {
                    title: "激活状态",
                    key: "platformUser",
                    width: 120,
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.platformUser ? (params.row.platformUser == 1 ? "是" : params.row.platformUser == 2 ? "否" : "") : "")
                    },
                },
                {
                    title: "操作",
                    key: "action",
                    align: "center",
                    width: 300,
                    fixed: "right",
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "info",
                                        size: "small",
                                    },
                                    on: {
                                        click: () => {
                                            this.messageRegion = true
                                            this.showRegion = true
                                            this.regionUserId = {
                                                custGlobalId: params.row.custGlobalId,
                                                orgCode: params.row.orgCode,
                                                userId: params.row.userId,
                                            }
                                        },
                                    },
                                },
                                "设置负责区域"
                            ),
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "info",
                                        size: "small",
                                    },
                                    style: {
                                        marginLeft: "14px",
                                    },
                                    on: {
                                        click: () => {
                                            this.getDetail(params.row.userId)
                                        },
                                    },
                                },
                                "修改"
                            ),
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "info",
                                        size: "small",
                                    },
                                    style: {
                                        marginLeft: "14px",
                                    },
                                    on: {
                                        click: () => {
                                            this.$Modal.confirm({
                                                title: "温馨提示",
                                                content: "您正在删除此数据，请确定是否继续?",
                                                onOk: () => {
                                                    this.$post("/syaa/pc/sy/user/communityStructure/delete", {
                                                        id: params.row.id,
                                                    }).then(res => {
                                                        if (res.code == 200) {
                                                            this.$Message.success({
                                                                content: "删除成功",
                                                                background: true,
                                                            })
                                                            this.page = this.currentPage
                                                            this.getList(this.CascaderId)
                                                        } else {
                                                            this.$Message.error({
                                                                content: res.desc,
                                                                background: true,
                                                            })
                                                            return
                                                        }
                                                    })
                                                },
                                            })
                                        },
                                    },
                                },
                                "删除"
                            ),
                        ])
                    },
                },
            ],
            loading: false,
            //当前页
            page: 1,
            total: 0,
            pageSize: 20,
            currentPage: 0,
            CascaderList: [],
            CascaderId: [],

            // 导入的参数
            excelStatus: false,
            exportAction: "",
            uploadData: {
                orgCode: "",
                oemCode: parent.vue.oemInfo.oemCode,
            },

            showExcel: 0,
            nodeList: [],
            //异步加载社区
            loadingCommunity: false,
            nodeId: "",
            allNodeList: [],

            // 发消息
            messageStatus: false,
            showNumber: 0,
            sendDisabled: false,
            sendDidsabled: false,

            //  设置负责区域
            messageRegion: false,
            regionUserId: {},
            showRegion: false,
            // 类型数组
            // typeList:[]

            // 修改
            editStatus: false,
            detaiMessage: {},
            selectList: [], //身份标签数组
            editSelect: [], //身份标签已选择的标签

            isNum: 0,
            communityCode: "",
            //身份类型
            identityType: [],

            // 初始化默认社区
            orgCodeValue: sessionStorage.getItem("RESIDENT_DATABASE_ORGCODE") || null,
        }
    },
    methods: {
        // 返回事件
        backClick() {
            if (this.$route.query.isLineHome && !this.$route.query.isback) this.$router.push("/residentdatabase")
            if (this.$route.query.isLineHome && this.$route.query.isback) this.$router.push(`/mobilizationsystem?isLineHome=${this.$route.query.isLineHome}`)
        },
        // 修改模态框确认按钮事件
        editSave() {
            if ((this.detaiMessage.name ?? "") == "") {
                this.$Message.warning({
                    content: "姓名不能为空",
                    background: true,
                })
                return
            }
            if ((this.detaiMessage.addr ?? "") == "") {
                this.$Message.warning({
                    content: "地址不能为空",
                    background: true,
                })
                return
            }
            if (this.editSelect.length == 0) {
                this.$Message.warning({
                    content: "请选择身份标签",
                    background: true,
                })
                return
            }
            let list = []
            this.selectList.map(item => {
                if (this.editSelect.join(",").indexOf(item.extendValue) > -1) {
                    list.push({
                        labelType: item.label,
                        labelCode: item.value,
                    })
                }
            })
            let data = {
                userId: this.detaiMessage.userId,
                orgCode: this.detaiMessage.orgCode,
                addr: this.detaiMessage.addr,
                name: this.detaiMessage.name,
                userItems: list,
            }
            this.updateRow(data)
        },
        // 关闭修改模态框事件
        editInput(status) {
            this.editStatus = status
        },
        sendRegion() {
            let data = this.$refs.region
            if (data.menuName == "grid") {
                if (data.tableData.gridIdList.length == 0) {
                    this.$Message.warning({
                        content: "请选择网格数据",
                        background: true,
                    })
                    return
                }
                let parmas = {
                    gridIdList: data.tableData.gridIdList,
                    regionIdList: [],
                    houseCodeList: [],
                }
                this.structureRegion(parmas)
                // 如果是网格的
            } else if (data.menuName == "build") {
                // 如果是楼栋的
                console.log("data.tableData.regionGovernmentVos", data.tableData.regionGovernmentVos)
                if (data.tableData.regionGovernmentVos.length == 0) {
                    this.$Message.warning({
                        content: "请选择楼栋",
                        background: true,
                    })
                    return
                }
                let arr = []
                data.tableData.regionGovernmentVos.map(item => {
                    arr.push(item)
                })
                let parmas = {
                    gridIdList: [],
                    regionIdList: arr,
                    houseCodeList: [],
                }
                this.structureRegion(parmas)
            } else if (data.menuName == "hu") {
                // 如果是户的
                console.log("data.tableData.houseGovernmentVos", data.tableData.houseGovernmentVos)
                if (data.tableData.houseGovernmentVos.length == 0) {
                    this.$Message.warning({
                        content: "请选择户",
                        background: true,
                    })
                    return
                }
                let arr = []
                data.tableData.houseGovernmentVos.map(item => {
                    if (item.code && item.code != "") {
                        arr.push(item.code)
                    }
                    if (item.houseNo && item.houseNo != "") {
                        arr.push(item.houseNo)
                    }
                })
                let parmas = {
                    gridIdList: [],
                    regionIdList: [],
                    houseCodeList: arr,
                }
                this.structureRegion(parmas)
            }
        },
        //   负责区域模态框关闭
        regionInput(status) {
            if (!status) {
                this.messageRegion = status
                this.regionUserId = {}
                this.showRegion = false
            } else {
                this.messageRegion = status
            }
        },
        // 开通短信提示
        changeOpenTips(status) {
            this.openTips = status
        },
        goOpen() {
            this.opLoading = true
            this.$post(
                "/syaa/pc/sy/smsChannel/submitChannel",
                {
                    orgCode: parent.vue.loginInfo.userinfo.orgCode,
                    custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                    channelId: "1",
                },
                { "Content-Type": "application/json" }
            ).then(res => {
                if (res.code == 200) {
                    this.$Message.success({
                        content: res.desc,
                        background: true,
                    })
                }
                if (res.code == 500) {
                    this.$Message.warning({
                        content: res.desc,
                        background: true,
                    })
                }
            })
            this.opLoading = false
            this.changeOpenTips(false)
        },
        notOpen() {
            this.changeOpenTips(false)
        },
        // 点击发消息按钮
        openMessage() {
            this.$get("/syaa/pc/sy/sms/checkSmsChannel", {
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
            }).then(res => {
                if (res.code == 200) {
                    this.messageStatus = true
                    ++this.showNumber
                    this.sendDidsabled = false
                }
                if (res.code == 500) {
                    this.changeOpenTips(true)
                }
            })
        },
        // 发消息
        messageInput(status) {
            this.messageStatus = status
        },
        // 弹窗返回按钮
        messageBack() {
            this.messageStatus = false
        },
        // 弹窗确认按钮
        messageSend() {
            if (this.$refs.message.selectList.length == 0) {
                this.$Message.warning({
                    content: "请选择接收人",
                    background: true,
                })
                return
            }
            if (this.$refs.message.formData.content == "") {
                this.$Message.warning({
                    content: "请填写短信内容",
                    background: true,
                })
                return
            }
            let data = []
            this.$refs.message.selectList.map(item => {
                data.push(item.id)
            })
            this.sendRow(data)
        },
        sendRow(data) {
            this.sendDidsabled = true
            this.$post("/syaa/pc/sy/user/communityStructure/sendMemberSms", {
                staffOrgCode: parent.vue.loginInfo.userinfo.orgCode,
                staffName: parent.vue.loginInfo.userinfo.realName,
                businessCode: "3",
                oemCode: parent.vue.oemInfo.oemCode,
                smsMsg: this.$refs.message.formData.content,
                ids: data.join(","),
            }).then(res => {
                this.sendDidsabled = false
                if (res.code == 200) {
                    this.messageStatus = false
                    this.$Message.success({
                        content: "短信发送成功",
                        background: true,
                    })
                } else {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                    return
                }
            })
        },
        // 导入成功回调事件
        excelSuccess() {
            this.excelStatus = false
            this.page = 1
            this.pageSize = 20
            this.getList(this.CascaderId)
        },
        //修改excel导入弹窗状态
        excelModalData(status) {
            this.excelStatus = status
            // this.showExcel = false
        },
        // 点击导入按钮
        importExcel() {
            this.nodeId = ""
            this.uploadData.orgCode = ""
            this.excelStatus = true
            this.showExcel = this.showExcel + 1
            this.exportAction = window.vue.getProxy()["/syaa"].target + "/upload/sy/user/imp/importUserImp"
        },
        hadlePageSize(val) {
            this.page = val.page
            this.pageSize = val.pageSize
            this.getList(this.CascaderId)
        },
        // 搜索
        inputSearch(val) {
            if (this.CascaderId.length != 0 && this.CascaderId.length != 3) {
                this.$Message.warning({
                    content: "请选择到社区进行查询",
                    background: true,
                })
                return
            } else {
                this.CascaderList.map(item => {
                    console.log(item.value)
                    console.log(this.CascaderId[this.CascaderId.length - 1])
                    if (item.value == this.CascaderId[this.CascaderId.length - 1]) {
                        this.orgId = item.orgId
                        //  console.log(this.orgId);
                    }
                })
                this.getList(this.CascaderId)
                this.getNum(this.CascaderId[this.CascaderId.length - 1])
            }
            // if(this.CascaderId.length == 0) this.getList();
        },
        //重置
        reset() {
            this.page = 1
            this.pageSize = 20
            this.selectForm.labelCode = ""
            this.selectForm.name = ""
            this.selectForm.mobile = ""
            this.selectForm.activeStatus = ""
            // this.CascaderId = []
            this.getList(this.CascaderId)
        },
        //分页
        getList(data) {
            console.log(data)
            ;(this.communityCode = data && data.length != 0 && data.length == 3 ? data[data.length - 1] : ""), console.log(this.communityCode)
            this.loading = true
            this.$get("/syaa/pc/sy/user/communityStructure/selectUserStructurePage", {
                hasSensitive: this.hasSensitive,
                page: this.page,
                pageSize: this.pageSize,
                staffOrgCode: parent.vue.loginInfo.userinfo.orgCode,
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                orgCode: data && data.length != 0 && data.length == 3 ? data[data.length - 1] : "",
                mobile: this.selectForm.mobile,
                name: this.selectForm.name,
                platformUser: this.selectForm.activeStatus,
                labelCode: this.selectForm.labelCode,
            })
                .then(res => {
                    this.loading = false
                    if (res.code == 200) {
                        if (res.dataList && res.dataList.length != 0) {
                            res.dataList.map(item => {
                                let label = []
                                let position = []
                                if (item.userItems && item.userItems.length != 0) {
                                    item.userItems.map(items => {
                                        // label =  items.labelName + ',' + label
                                        if (items.labelType == 5) {
                                            label.push(items.labelName)
                                        } else if (items.labelType == 8) position.push(items.labelName)
                                    })
                                }
                                item.labelName = label
                                item.positionName = position
                            })
                        }
                        this.tableData = res.dataList
                        this.total = res.maxCount
                        this.currentPage = res.currentPage
                    } else {
                        this.$Message.error({
                            content: res.desc,
                            background: true,
                        })
                        return
                    }
                })
                .catch(err => {
                    this.$Message.error({
                        content: err,
                        background: true,
                    })
                })
        },
        changeEye() {
            this.hasSensitive = !this.hasSensitive
            this.getList(this.CascaderId)
        },

        // 获取级联
        getCascader() {
            this.$get("/orgzz/pc/organization/selectCascadeDataScope", {
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCode: "4401",
                orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
            }).then(res => {
                if (res.code == 200 && res.dataList.length != 0) {
                    this.CascaderList = this.forMatterTree(res.dataList)
                    this.CascaderList.map(item => {
                        item.loading = false
                    })
                    if (this.isNum == 0 && this.CascaderList.length != 0) {
                        console.log(this.CascaderList)
                        this.CascaderId.push(this.CascaderList[0].value)
                    }
                }
            })
        },

        forMatterTree(data) {
            for (var i = 0; i < data.length; i++) {
                data[i].label = data[i].orgName
                data[i].value = data[i].orgCode
                data[i].orgId = data[i].orgId
                if (data[i].children && data[i].children != null && data[i].children.length > 0) {
                    this.forMatterTree(data[i].children)
                }
            }
            return data
        },
        loadData(item, callback) {
            item.loading = true
            let params = {
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCode: item.orgCode,
                orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
            }
            this.$get("/orgzz/pc/organization/selectCascadeDataScope", {
                ...params,
            }).then(res => {
                if (res.code == 200 && res.dataList.length != 0) {
                    let arr = this.forMatterTree(res.dataList)
                    arr.map(items => {
                        if (items.orgLevel == 4) {
                            items.loading = false
                        }
                    })
                    item.loading = false
                    item.children = arr
                    callback(item.children)
                    if (this.isNum == 0 && item.children.length != 0) {
                        this.CascaderId.push(item.children[0].value)
                        this.isNum = 1
                        return
                    } else if (this.isNum == 1 && item.children.length != 0) {
                        //  this.orgId = item.children[0].orgId
                        this.CascaderId.push(item.children[0].value)
                        this.isNum = 0
                        this.getList(this.CascaderId)
                        this.getNum(this.CascaderId[this.CascaderId.length - 1])
                    }
                } else {
                    callback([])
                }
            })
        },
        getSelect() {
            this.$get("/old/api/pc/project/selectStaffManagerCommunity", {
                staffId: parent.vue.loginInfo.userinfo.id,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
            })
                .then(res => {
                    if (res.code === "200" && res.dataList && res.dataList.length > 0) {
                        // this.getTree(this.communityCode);   //第一次进来默认获取第一个社区数据
                        this.nodeList = res.dataList.map(item => {
                            return {
                                value: item.code,
                                label: item.fullName,
                                projectCode: item.projectCode,
                            }
                        })
                        this.allNodeList = JSON.parse(JSON.stringify(this.nodeList))
                        if (this.nodeList.length > 50) {
                            this.nodeList.length = 50
                        }
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },
        getTree(val) {
            this.nodeId = val
            this.uploadData.orgCode = this.nodeId
        },
        //异步加载社区
        loadingCommunityFn(query) {
            if (query !== "" && !Number(query)) {
                this.loadingCommunity = true
                this.nodeList = this.allNodeList.filter(item => item.label.toLowerCase().indexOf(query.toLowerCase()) > -1)
                if (this.nodeList.length > 50) this.nodeList.length = 50
                this.loadingCommunity = false
            } else if (Number(query)) {
                return
            } else {
                this.nodeList = []
            }
        },
        // 设置区域范围接口
        structureRegion(data) {
            this.$post(
                "/syshequ/api/sytop/pc/grid/structureRoleSetting",
                {
                    ...this.regionUserId,
                    gridIdList: data.gridIdList,
                    regionIdList: data.regionIdList,
                    houseCodeList: data.houseCodeList,
                },
                { "Content-Type": "Application/json" }
            ).then(res => {
                if (res.code == 200) {
                    this.$Message.success({
                        content: "设置成功",
                        background: true,
                    })
                    this.messageRegion = false
                    this.showRegion = false
                    this.regionUserId = {}
                    this.page = this.currentPage
                    this.getList(this.CascaderId)
                } else {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                    return
                }
            })
        },
        // 获取详情接口
        getDetail(userId) {
            this.$get("/syaa/pc/sy/user/communityStructure/selectUserInfo", {
                userId,
            }).then(res => {
                if (res.code == 200) {
                    this.detaiMessage = res.data
                    this.editStatus = true
                    this.getLabel()
                    if (res.data.userItems && res.data.userItems.length != 0) {
                        this.editSelect = []
                        res.data.userItems.map(item => {
                            this.editSelect.push(item.labelCode)
                        })
                    }
                } else {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                    return
                }
            })
        },
        // 获取身份标签接口
        getLabel() {
            this.$get("/voteapi/api/common/sys/findDictByDictType", {
                dictType: "USER_IMP_TYPE",
            }).then(res => {
                if (res.code == 200) {
                    res.dataList.map(item => {
                        item.label = item.dictValue
                        item.value = item.extendValue
                    })
                    this.selectList = res.dataList
                } else {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                    return
                }
            })
        },
        // 修改接口
        updateRow(data) {
            this.$post(
                "/syaa/pc/sy/user/communityStructure/updateUserInfo",
                {
                    ...data,
                },
                { "Content-Type": "application/json" }
            ).then(res => {
                if (res.code == 200) {
                    this.$Message.success({
                        content: "修改成功",
                        background: true,
                    })
                    this.editStatus = false
                    this.page = this.currentPage
                    this.getList(this.CascaderId)
                } else {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                    return
                }
            })
        },
        // 获取多少个数据
        getNum(orgCode) {
            this.$get("/syaa/pc/sy/user/communityStructure/selectStatusCount", {
                orgCode,
                status: "1",
            }).then(res => {
                if (res.code == 200) {
                    this.num = res.data || "0"
                } else {
                    this.$Message.error({
                        content: "获取个数失败",
                        background: true,
                    })
                    return
                }
            })
        },
        // 获取字典接口的公共方法
        getDicType(type, model) {
            this.$get("/voteapi/api/common/sys/findDictByDictType", {
                dictType: type,
            }).then(res => {
                this[model] = res.dataList
            })
        },
        formatterData(val, data) {
            this.orgId = ""
            if (data && data.length > 0) {
                data.map(item => {
                    if (item.value == val) {
                        this.orgId = item.orgId
                    } else {
                        if (item.children && item.children.length > 0) {
                            this.formatterData(val, item.children)
                        }
                    }
                })
            }
        },

        changeCasder(val) {
            if (val?.length === 3) {
                this.CascaderId = val
                this.getList(this.CascaderId)
                this.getNum(this.CascaderId[this.CascaderId.length - 1])
            }
        },
    },
    components: {
        LiefengContent,
        LiefengTable,
        LiefengModal,
        LiefengUploadExcel,
        Message,
        Region,

        LiefengCascaderCity,
    },
    async created() {
        this.getDicType("USER_IMP_TYPE", "identityType")
        // this.getList()
        await this.getCascader()
        await this.getSelect()
    },
    watch: {
        CascaderId(val) {
            console.log(val)
            if (val && val.length > 0) {
                this.formatterData(val[val.length - 1], this.CascaderList)
                //  this.orgId = this.formatterData(val[val.length - 1],this.CascaderList)S
            }
        },
    },
}
</script>

<style scoped lang="less">
.modal {
    display: flex;
    padding: 0 20px;
    justify-content: space-between;
    flex-wrap: wrap;
    .ivu-form-item {
        width: 45%;
    }
}
/deep/.ivu-upload-select {
    display: block;
    margin: 10px 20px;
    border: 1px dotted rgb(15, 121, 241);
    cursor: pointer;
}

.phone {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}

/deep/.ivu-drawer-body {
    .ivu-form-item {
        width: 100%;
        margin-bottom: 0;
    }
}
.demo-drawer-footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    background: #fff;
}
.export-modal {
    /deep/ #modal_contentarea {
        overflow: visible !important;
        height: 270px;
    }
}
/deep/.validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}
.eye {
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    margin: 0 15px 0;
}
/deep/.ivu-select-multiple .ivu-select-selection div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>
